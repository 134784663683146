import Model from '@/util/Model'

export default class File extends Model {
  constructor (data = {}) {
    const fields = [
      {
        name: 'name',
        type: 'string'
        // required: true
      },
      {
        name: 'description',
        type: 'string'
      },
      {
        name: 'filename',
        type: 'string'
      },
      {
        name: 'url',
        type: 'string'
      },
      {
        name: 'path',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string',
        default: 'user'
      },
      {
        name: 'subType',
        type: 'string'
      },
      {
        name: 'user',
        type: 'object', // using id
        idOnly: true
        //   default: {}
      },
      {
        name: 'mimeType',
        type: 'string'
      },
      {
        name: 'classes',
        type: 'string'
      },
      {
        name: 'category', // Category _id
        type: 'array',
        idOnly: true,
        default: []
      },
      {
        name: 'order',
        type: 'number'
      },
      {
        name: 'default',
        type: 'boolean',
        default: false
      },
      {
        name: 'created',
        type: 'date'
      },
      {
        name: 'modified',
        type: 'date'
      },
      {
        name: 'content',
        type: 'localizable',
        members: ['name', 'description'],
        default: { en: {}, fr: {} }
      }
    ]

    super({ fields: fields }, data)
  }
}

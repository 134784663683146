<template>
  <div
    v-if="visible"
    id="modal-template"
    class="modal"
    transition="modal"
    @show="show"
    @hide="close"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <!-- <div class="modal-container" :style="{width: modalwidth, height: modalheight}"> -->
        <!-- <div class="modal-container" :style="{width: modalwidth}"> -->
        <div class="modal-container">
          <div class="modal-header" align="center">
            <h2>{{ title }}</h2>
            <slot name="header">
              <a v-if="closable" class="modal-default-button" @click="close()">
                <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
              </a>
            </slot>
          </div>

          <div class="modal-body" align="center">
            <p v-show="showmsg" class="warn">{{ showmsg }}</p>
            <span v-html="body"></span>
          </div>

          <div class="modal-actions">
            <div
              class="control"
              v-for="(action, index) in actions"
              :key="index"
            >
              <div v-if="action.type === 'hidden'">
                <input
                  type="hidden"
                  :name="action.name"
                  v-model="actionValues[action.name]"
                />
              </div>
              <div v-if="action.type === 'radio'">
                <label>{{ action.label }}</label>
                <label v-for="(btn, index) in action.value" :key="index">
                  <input
                    type="radio"
                    :name="btn.name"
                    v-model="actionValues[btn.name]"
                    :disabled="btn.disable"
                  />{{ btn.label }}
                </label>
              </div>
              <div v-else>
                <input
                  :name="action.name"
                  v-model="actionValues[action.name]"
                  :type="action.type"
                />
                <label :for="action.name">{{ action.label }}</label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-for="(button, index) in buttons"
              :key="index"
              class="button"
              :class="button.class"
              @click.stop.prevent="handleClick(button.handler)"
              :disabled="button.disabled"
            >
              {{ button.text }}
            </button>
            <slot name="footer">
              <!-- <button class="modal-default-button" @click="close()">close</button> -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash/fp'

export default {
  data () {
    return {
      actionValues: {},
      input: null,
      res: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modalheight: { type: String, default: '90%' },
    modalwidth: String,
    closable: {
      type: Boolean,
      default: true
    },
    title: String,
    body: String,
    showmsg: String,
    buttons: Array,
    actions: {
      type: Array,
      default: () => ({ type: null })
    },
    callback: Function
  },
  methods: {
    handleClick (fn) {
      let _res = fn()
      const _return = _.assign(this.actionValues, { res: _res })
      if (typeof this.callback === 'function')
        this.callback(_return, this.input, this.close)
      else this.close(_return)
    },
    close (val) {
      // this.showModal = false
      this.$emit('hide', val)
    },
    show (val) {
      console.info(' :: modal heard show')
      this.input = val
      _.each(i => {
        this.actionValues[i.name] = i.value
      }, this.actions)
      this.res = ''
      // this.showModal = true
    }
  },
}
</script>

<style type="text/css" media="screen">
p.warn {
  text-align: center;
  color: red;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: fixed;
  /*    width: 300px;*/
  /*    margin: 0px auto;*/
  /*    padding: 20px 30px;*/
  /*    padding: 3rem;*/
  padding: 0.5rem 0.5rem 2rem 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  /*    transition: all .3s ease;*/
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  text-align: center;
}

.modal .modal-body {
  margin: 2rem 0 1rem 0;
  text-align: left;
  padding: 1.5rem 1.5rem 0 0;
}
.modal-body ul {
  margin-left: 1rem;
}

.modal-actions,
.modal-actions.control {
  text-align: center;
}

.modal-default-button {
  float: right;
}

.modal-footer {
  text-align: center;
}

button.primary {
  margin-right: 1rem;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
